<template>
  <a-checkbox-group
    :value="checkedValue"
    :disabled="record.options.disabled"
    :name="record.options.model"
    @change="change"
  >
    <a-checkbox
      v-for="(item, index) in options"
      :key="index"
      :autoFocus="item.autoFocus"
      :checked="item.checked"
      :disabled="item.disabled"
      :indeterminate="item.indeterminate"
      :value="item[valueKey]"
    >
      {{item[labelKey]}}
    </a-checkbox>
  </a-checkbox-group>
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxCheckbox',
  mixins: [formEleMixin],
  computed: {
    checkedValue () {
      const { labelInValue } = this.record.options
      if (labelInValue) {
        return this.value.map(value => value.key)
      }
      return this.formatValue(this.value, 'toArr')
    }
  },
  methods: {
    /**
     * 变化时回调函数
     * 要保存label的时候，返回去的值肯定是对象数组无法切割成字符串
     * 也不会把label和value分两个字段存，由页面自行处理
     * @param checkedValue
     */
    change (checkedValue) {
      const { labelInValue, isValueArray } = this.record.options
      let value
      if (labelInValue) {
        value = checkedValue.map(value => {
          const label = this.options.find(option => option[this.valueKey] === value)?.[this.labelKey]
          return { key: value, label: label }
        })
      } else {
        value = isValueArray ? checkedValue : this.formatValue(checkedValue, 'toStr')
      }
      this.$emit('change', value, this.record.model)
    }
  }
}
</script>
